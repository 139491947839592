import React, { useEffect, useState } from 'react';
import { useConnectWallet } from '@web3-onboard/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Wallet } from '../../onboard/onboard';
import ModalMenu from '../Modal/ModalMenu';
import RandomImage from '../common/RandomLogo';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const NewTag = () => {
  return (
      <span className="new-tag">New</span>
  );
};

const Header = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const userData = useSelector((state) => state?.state);

  const BlockedUserToast = () => (
      <div>
        <Link to="https://discord.gg/MMUJR3PFNE" target="_blank">
          You have been blocked from accessing Timmy Finance, Join our discord to appeal.
        </Link>
      </div>
  );

  useEffect(() => {
    const checkBlockedUser = async () => {
      if (wallet) {
        try {
          const response = await axios.get(`https://stakingapi.timmy.finance/settings/blocked?wallet=${wallet.accounts[0]?.address}`);
          if (response.data['exists']) {
            toast.error(BlockedUserToast);
            await disconnect(wallet);
            localStorage.removeItem('connectedWallet');
            return;
          }
          if (
              wallet?.accounts[0]?.address.toLowerCase() ===
              '0xBFf06DEb7059B475a734E71D101b2D9fF3247cFF'.toLowerCase()
          ) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error('Error checking blocked user:', error);
        }
      } else {
        setIsAdmin(false);
      }
    };

    checkBlockedUser();
  }, [wallet, disconnect]);

  return (
      <>
        <header id="header">
          <nav
              data-aos="zoom-out"
              data-aos-delay={800}
              className="navbar gameon-navbar navbar-expand"
          >
            <div className="container header">
              <RandomImage />
              <div className="ml-auto" />
              <ul className="navbar-nav items mx-auto">
                <li className="nav-item">
                  <a href="https://staking.timmy.finance" className="nav-link">
                    Home{' '}
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="stakingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    Projects
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="stakingDropdown">
                    <li>
                      <Link
                          to="https://timmy.finance/scrappies"
                          className="dropdown-item dropdown-item-black"
                      >
                        Scrappies
                      </Link>
                    </li>
                    <li>
                      <Link
                          to="https://timmy.finance/degen-donkeys"
                          className="dropdown-item dropdown-item-black"
                      >
                        Degen Donkeys V2 <NewTag/>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link">
                    Arcade <i className="icon-arrow-down"></i>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="https://timmy.finance/coinflip" className="nav-link">
                        Timmy flip <NewTag/>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="stakingDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    Staking
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="stakingDropdown">
                    <li>
                      <Link
                          to="https://staking.timmy.finance"
                          className="dropdown-item dropdown-item-black"
                      >
                        Staking <NewTag/>
                      </Link>
                    </li>
                  </ul>
                </li>
                {isAdmin && (
                    <li className="nav-item">
                  <span className="nav-link">
                    <Link to={'/admin'}>Admin</Link>
                  </span>
                    </li>
                )}
              </ul>
              <ul className="navbar-nav toggle">
                <li className="nav-item">
                  <Link
                      to="#"
                      className="nav-link"
                      data-toggle="modal"
                      data-target="#menu"
                  >
                    <i className="icon-menu m-0"/>
                  </Link>
                </li>
              </ul>
              <ul className="navbar-nav action">
                <Wallet/>
              </ul>
            </div>
          </nav>
        </header>
      </>
  );
};

export default Header;
