import React, { useState, useEffect, useRef } from 'react';
import { init, useConnectWallet } from '@web3-onboard/react';
import { ethers } from 'ethers';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import { ChainId } from '@usedapp/core';
import { useSetChain } from '@web3-onboard/react';
import axios from "axios";
import '../components/Header/walletDropdown.css';
import { useNavigate } from "react-router";

const injected = injectedModule();
const wcInitOptions = {
  projectId: '1745f1e7d29f43bea468f195d31c2528',
  requiredChains: [25, 137],
  dappUrl: 'https://timmy.finance'
};

const walletConnect = walletConnectModule(wcInitOptions);

const onboard = init({
  wallets: [injected],
  chains: [
    {
      id: '0x19',
      token: 'CRO',
      label: 'Cronos Mainnet',
      rpcUrl: 'https://evm.cronos.org'
    },
    {
      id: '0x89',
      token: 'MATIC',
      label: 'Polygon Mainnet',
      rpcUrl: 'https://polygon-mainnet.infura.io/v3/82c058d14e874c9eb52bd3a2caad71ce'
    }
  ],
  accountCenter: {
    mobile: { enabled: false },
    desktop: { enabled: false }
  },
  theme: 'dark'
});

export const Wallet = () => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [{ connectedChain }] = useSetChain();
  const [showDropdown, setShowDropdown] = useState(false);
  const [linkStatus, setLinkStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const dropdownRef = useRef(null);

  const chainImages = {
    '0x19': 'https://icons.llamao.fi/icons/chains/rsz_cronos.jpg',
    '0x152': 'https://icons.llamao.fi/icons/chains/rsz_cronos.jpg',
    '0x89': 'https://icons.llamao.fi/icons/chains/rsz_polygon.jpg'
  };
  const allowedChainIds = ['0x19', '0x152'];

  const showSwitchNetworkButton = !allowedChainIds.includes(connectedChain?.id);

  const navigate = useNavigate();

  let ethersProvider;
  if (wallet) {
    ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any');
    localStorage.setItem('connectedWallet', wallet.label);
  }

  useEffect(() => {
    const savedWallet = localStorage.getItem('connectedWallet');
    if (savedWallet) {
      connect({ autoSelect: { label: savedWallet, disableModals: true } }).catch(err =>
          console.error('Auto-connect failed:', err)
      );
    }
  }, [connect]);

  const walletDisconnectHandler = async () => {
    await disconnect(wallet).then(() => {
      localStorage.removeItem('connectedWallet');
    });
  };

  const walletConnectHandler = () => {
    connect()
        .then(({ wallets }) => {
          if (wallets && wallets.length > 0) {
            localStorage.setItem('connectedWallet', wallets[0].label);
          }
        })
        .catch(err => console.error('Connect failed:', err));
  };

  const switchNetwork = async (onboard) => {
    return new Promise(async (resolve, reject) => {
      try {
        await onboard.setChain({
          chainId: process.env.REACT_APP_ENVIRONMENT === 'DEV'
              ? ChainId.CronosTestnet
              : ChainId.Cronos
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const checkLinkStatus = async () => {
    if (wallet && wallet.accounts && wallet.accounts.length > 0) {
      const walletAddress = wallet.accounts[0].address;
      const userId = localStorage.getItem('discordUserId'); // Assuming you save discordUserId in localStorage after connecting Discord
      try {
        const response = await axios.get(`${process.env.REACT_APP_DISCORD_URI}/link/check`, {
          params: { userId, walletAddress }
        });
        if (response.data.linked) {
          if (response.data.link.walletAddress !== walletAddress || response.data.link.userId !== userId) {
            setLinkStatus('Unlink Discord');
          } else {
            setLinkStatus('Unlink Discord');
          }
        } else {
          setLinkStatus('Connect Discord');
        }
      } catch (error) {
        console.error('Error checking link status:', error);
        setLinkStatus('Error Checking Status');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    checkLinkStatus();
  }, [wallet]);

  const handleConnectDiscord = () => {
    if (linkStatus === 'Connect Discord') {
      // navigate('https://timmy.finance/discord/link')
      window.location.href = "https://timmy.finance/discord/link"
    } else if (linkStatus === 'Unlink Discord') {
      // navigate('https://timmy.finance/discord/unlink')
      window.location.href = "https://timmy.finance/discord/unlink"
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div className="nav-item ml-2">
        {wallet ? (
            showSwitchNetworkButton ? (
                <li className="custom-border" onClick={() => switchNetwork(onboard)}>
                  Switch Network
                </li>
            ) : (
                <div className="wallet-dropdown" ref={dropdownRef}>
                  <li
                      className="custom-border wallet-button"
                      onClick={() => setShowDropdown(!showDropdown)}
                  >
                    {`${wallet?.accounts[0]?.address.substring(0, 5)}...${wallet?.accounts[0]?.address.substring(39, 42)}`}
                    <img
                        src={chainImages[connectedChain?.id]}
                        alt="Connected Chain"
                        style={{
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                          marginLeft: '10px'
                        }}
                    />
                    <i className={`icon-arrow-${showDropdown ? 'up' : 'down'}`}></i>
                  </li>
                  {showDropdown && (
                      <ul className="dropdown-menu show">
                        <li onClick={handleConnectDiscord}>
                          <img src="https://timmy.finance/img/icons/discord.svg" alt="Discord" className="icon" /> {loading ? 'Loading...' : linkStatus}
                        </li>
                        <li onClick={walletDisconnectHandler}>
                          Disconnect Wallet
                        </li>
                      </ul>
                  )}
                </div>
            )
        ) : (
            <li
                className="custom-border"
                onClick={walletConnectHandler}
                disabled={connecting}
            >
              Connect Wallet
            </li>
        )}
      </div>
  );
};
