import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

const RandomImage = ({ width, height }) => {
    // Array of image URLs
    const images = [
        { url: 'https://i.imgur.com/KzUjtzC.gif', alt: 'Timmy finance', weight: 1 },
        // { url: 'https://timmy.finance/img/projects/alpacas.gif', alt: 'Pesky Alpacas', weight: 2 },
        // { url: 'https://timmy.finance/img/projects/scrappies.png', alt: 'Scrap Monsters', weight: 3 },
        // { url: 'https://timmy.finance/img/timmothy.png', alt: 'Boring old staking logo :(', weight: 10 },
    ];

    // Calculate total weight
    const totalWeight = images.reduce((acc, { weight }) => acc + weight, 0);

    // State to hold the randomly selected image URL and alt tag
    const [randomImage, setRandomImage] = useState({ url: '', alt: '' });

    // Function to select a random image URL and alt tag based on weights
    const selectRandomImage = () => {
        let cumulativeWeight = 0;
        const randomNumber = Math.random() * totalWeight;
        let randomIndex;
        for (let i = 0; i < images.length; i++) {
            const { url, alt, weight } = images[i];
            cumulativeWeight += weight;
            if (randomNumber <= cumulativeWeight) {
                randomIndex = i;
                break;
            }
        }
        setRandomImage(images[randomIndex]);
    };

    // Select a random image URL and alt tag when the component mounts
    useEffect(() => {
        selectRandomImage();
    }, []);

    return (
        <Link href="https://timmy.finance" className="navbar-brand">
            <img src={randomImage.url} alt={randomImage.alt} width={width} height={height} />
        </Link>
    );
};

export default RandomImage;
