import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RandomBackground = () => {
    // State to hold the randomly selected image URL
    const [randomBackground, setRandomBackground] = useState('');

    // Function to select a random image URL based on weights
    const selectRandomImage = (backgrounds) => {
        const totalWeight = backgrounds.reduce((acc, { weight }) => acc + weight, 0);
        let cumulativeWeight = 0;
        const randomNumber = Math.random() * totalWeight;
        let randomIndex;
        for (let i = 0; i < backgrounds.length; i++) {
            const { url, weight } = backgrounds[i];
            cumulativeWeight += weight;
            if (randomNumber <= cumulativeWeight) {
                randomIndex = i;
                break;
            }
        }
        setRandomBackground(backgrounds[randomIndex].url);
    };

    // Fetch data from the API endpoint
    useEffect(() => {
        axios.get('https://stakingapi.timmy.finance/settings/background')
            .then(response => {
                const data = response.data;
                // Check if data is an array and not empty
                if (Array.isArray(data) && data.length > 0) {
                    console.log('Background data:', data);
                    selectRandomImage(data);
                } else {
                    console.error('No valid data returned from API');
                }
            })
            .catch(error => {
                console.error('Error fetching data from API:', error);
            });
    }, []);

    return randomBackground;
};

export default RandomBackground;
